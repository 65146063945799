import React from 'react'
import { tableOfPersons } from '../../helpers/firmData';
import './Team.css';

export const Team = () => {
  const renderTitle = () => {
    return <h1 className='title titleTeam' id='downTitleText'>Nasz zespół</h1>;
  }

  const renderPersons = tableOfPersons.map((person) => {
    return (
      <div className='profile' key={person.id}>
        <img src={person.jpg} className="profileImage" alt={person.name}/>
        <h2 className="userName">{person.name}</h2>
      </div>
    )
  })

  return (
    <>
      {renderTitle()}
      <div className="profiles">
        {renderPersons}
      </div>
    </>
  )
}