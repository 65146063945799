import React from 'react'
import './Home.css';
import { Link } from 'react-scroll';

export const Home = ({ date }) => {
  const renderOrganizationMeetingForMobile = () => {
    return (
      <div className='orgMeetingMobile'>
        <h2 className='timeOfOrganization'>SPOTKANIE ORGANIZACYJNE</h2>
        <h2 className='timeOfOrganization'>{date.meetingDate}</h2>
        <h2 className='timeOfOrganization'>UL. HALLERA 6</h2>
      </div>
    )
  }

  const renderOrganizationMeetingForWeb = () => {
    return (
      <h2 className='orgMeetingWeb'>SPOTKANIE ORGANIZACYJNE, {date.meetingDate}, UL. HALLERA 6</h2>
    )
  }

  return (
    <div className='top'>
      <div className='headerBackground'>
        <div className='contentHeader'>
          <h1 className='titleHeader'>ROZPOCZĘCIE NOWEGO KURSU</h1>
          <h1 className='dateStartCourse'>{date.courseDate}</h1>
          {renderOrganizationMeetingForMobile()}
          {renderOrganizationMeetingForWeb()}
          <div className='linkToAddCourseHeader'>
            <Link activeClass="active" smooth spy to="contact">
              <p className='buttonAddToCourseMenu'>
                Zapisz się już dzisiaj!
              </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
