import React, { useState } from 'react'
import './Menu.css';
import { logo, team, edit, shopping, users, facebook, instagram, phone, mail } from '../../assets/images';
import { Link } from 'react-scroll';

export const Menu = () => {
  const [showNav, setShowNav] = useState(false)

  const toggleNavItems = () => {
    setShowNav(!showNav)
  }

  const closeNav = () => {
    setShowNav(false);
  }


  return (
    <div className={`${showNav && 'helperDarkBox'}`}>
      <nav className="navbar">
        <div className="container">
          <div>
            <Link activeClass="active" smooth spy to="home" className='link'><a href='#top'><img className="logo" src={logo} alt="Logo"/></a></Link>
          </div>
          <div className="menu-icon" onClick={toggleNavItems}>
            <label className='menu-button-container' htmlFor="menu-toggle" />
            <div className='menu-button'></div>
          </div>
          <div className={`nav-elements  ${showNav && 'active'}`}>
            <ul>
              <li style={{display: showNav ? 'block' : 'none'}}>
                <Link activeClass="active" smooth spy to="contact" className='link' onClick={closeNav}>
                  <button className='buttonAddToCourseMenu'>Zapisz się już dzisiaj!</button>
                </Link>
              </li>
              <li>
                <Link activeClass="active" smooth spy to="about" className='link' onClick={closeNav}>
                  <div className='menuItemContainer'>
                    <img className='menuIcon' src={team} alt="team" />
                    o nas
                  </div>
                </Link>
              </li>
              <li>
                <Link activeClass="active" smooth spy to="team" className='link' onClick={closeNav}>
                  <div className='menuItemContainer'>
                    <img className='menuIcon' src={users} alt="users" />
                    zespół
                  </div>
                </Link>
              </li>
              <li>
                <Link activeClass="active" smooth spy to="offer" className='link' onClick={closeNav}>
                  <div className='menuItemContainer'>
                    <img className='menuIcon' src={shopping} alt="shopping" />
                    oferta
                  </div>
                </Link>
              </li>
              <li>
                <Link activeClass="active" smooth spy to="contact" className='link' onClick={closeNav}>
                  <div className='menuItemContainer'>
                    <img className='menuIcon' src={edit} alt="edit" />
                    kontakt
                  </div>
                </Link>
              </li>
              <li className='signInRight'>
                <Link activeClass="active" smooth spy to="contact" className='link'>
                  <button className='buttonAddToCourseMenu'>Zapisz się już dzisiaj!</button>
                </Link>
              </li>
            </ul>
            <div className='iconsMenu'>
              <a href="https://www.facebook.com/oskbolidrsl" target="_blank" rel="noreferrer"><img className='iconMenu' src={facebook} alt="facebook"/></a>
              <a href = "mailto: oskbolidrsl@gmail.com" target="_blank" rel="noreferrer"><img className='iconMenu' src={mail} alt="email"/></a>
              <a href="https://instagram.com/oskbolid?igshid=YmMyMTA2M2Y=" target="_blank" rel="noreferrer"><img className='iconMenu' src={instagram} alt="instagram"/></a>
              <a href="tel:696-447-501"><img className='iconMenu' src={phone} alt="phone"/></a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}