import React from 'react'
import { tableOfFeature } from '../../helpers/firmData';
import './AboutUs.css';

export const AboutUs = () => {
  const renderTitle = () => {
    return (
      <>
        <h2 className='text subtitle'>Szkolimy z pasją</h2> 
        <h2 className='text subtitle' id="downTitleText">od 2007 roku</h2>
      </>
    );
  }

  const renderFeature = tableOfFeature.map((feature) => {
    return (
      <div className='feature' key={feature.id}>
        <img className='featureImg'src={feature.jpg} alt="feature"/>
        <h2 className='featureTitle'>{feature.title}</h2>
        <p className='featureDescription'>{feature.description}</p>
      </div>
    )
  })

  return (
    <section>
      {renderTitle()}
      <div className='featuresContainer'>
        {renderFeature}
      </div>
    </section>
  )
}
